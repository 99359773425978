<template>
    <div class="ai-video-list">
      <el-tabs  class="menu-tabs" >
        <!-- 我的视频 -->
        <div style="margin-bottom:20px">
          <el-radio-group v-model="searchtype"  @change="radiochange"  >
            <el-radio-button   label="单词" ></el-radio-button>
            <el-radio-button  label="词组"></el-radio-button>
          </el-radio-group> 
        </div>
        <div style="display: flex;
    justify-content: space-between; margin-bottom: 15px;
    align-items: center;">    
            <el-input
              v-model="userNowInput"
              @keyup.enter="imagehandleSubmit"
              class="w-50 m-2 inputask"
              style="
                margin-right: 16px;
               
                height: 48px;
                border-radius: 12px;
              " 
            ></el-input>
            <img src="../assets/video/sentencesearchbtn.svg" alt="" style="cursor: pointer;" @click="getSentencesList(1)">
        </div>
        <ul class="listcontent scrollbardiv" v-infinite-scroll="getSentencesList" :infinite-scroll-distance="400" 
           >
          <li v-for="(item, index) in sentenceList" :key="index" style="color:white;margin:30px 0">
              <div style="display:flex">
                  <div>{{index+1}}.{{item.words_list}}</div>
                  <img src="../assets/video/recordopen.svg" alt="" style="width:24px;height:24px;margin-right:15px;margin-left:15px;cursor: pointer;" @click="openresource(item.tape_url)">
                  <img src="../assets/video/videoopen.svg" alt="" style="width:24px;height:24px;cursor: pointer;" @click="openVideo(item.video_id)">
              </div>
              <div style="margin:10px 0;    display: flex;align-items: center;">
                  <img src="../assets/video/sentenceyi.svg" alt="" style="width:20px;height:20px;margin-right:5px">
                  <span v-if="item.translate_words != ''" style="font-size:14px">{{item.translate_words}}</span>
                  <span v-else style="font-size:14px">暂无翻译</span>
              </div>
              <div style="color:gray;font-size:12px;cursor: pointer;" @click="openVideo(item.video_id)">{{item.video_name}}</div>
          </li>
        </ul>
      </el-tabs>
    </div>
  </template>
  
<script >
import api from '../api/video';
import { Delete, Edit, Search, Share, Upload } from '@element-plus/icons-vue'
export default {
    data() {
        return {
            userNowInput:"",
            searchtype:"单词",
            sentenceList:[],
            videoCursor:1,
        };
    },
    created() {
      this.getSentencesList();
    },
    methods:{
        imagehandleSubmit(){
        },
        openresource(url){
          window.open(url);
        },
        getSentencesList(id){
          if(id == 1){
            this.videoCursor = 1;
          this.sentenceList = [];
          }
          if(this.videoCursor == 0){
            return
          }
          if(this.searchtype == '单词'){
            var res = api.searchSentence({
              word: this.userNowInput,
              cursor: this.videoCursor,
              limit: 20
            }).then((res) => {
              if (res.code === 0) {
                res.data.msg.forEach(element => {
                    this.sentenceList.push(element)
                });
                this.videoCursor = res.data.cursor;
                this.$forceUpdate()
              }
            })
          }else{
               api.searchSenPhrase({
                  phrase: this.userNowInput,
                  cursor: this.videoCursor,
                  limit: 20
                }).then((res) => {
                if (res.code === 0) {
                  res.data.msg.forEach(element => {
                    this.sentenceList.push(element)
                  });
                  this.videoCursor = res.data.cursor;
                }
            })
          }
        },
        radiochange(name){
          this.userNowInput = ""
          this.videoCursor = 1;
          this.sentenceList = [];
          this.getSentencesList();
        },
        openVideo(id){
          var res =this.$router.resolve({
              path: '/ai-video/detail',
              query: {
                video_id: id
              }
            })
          window.open(res.href, '_blank');
        }

    }
}

</script>

<style lang="scss" scoped>
.listcontent{
  overflow: auto;
}
::v-deep .selectgpt .el-input__wrapper {
  background-color: #fbd026;
  border-radius: 20px;
  color: #fff;
  box-shadow: none;
}
::v-deep .el-radio-button__inner{
  background-color: #ffffff0d;
  border-color: #ffffff0d !important;
}
::v-deep .el-radio-button__original-radio:checked+.el-radio-button__inner{
  background: #006FF9;
}
::v-deep .el-radio-button__inner:hover {
    color: #fff !important;
}

::v-deep .selectgpt .el-input.is-focus .el-input__wrapper {
  box-shadow: none !important;
}

::v-deep .selectgpt .el-input__wrapper.is-focus {
  box-shadow: none !important;
}

::v-deep .inputask .el-input__wrapper {
  background-color: #ffffff0d;
  border-radius: 8px;
  box-shadow: none;
}

::v-deep .inputask .el-input__inner {
    padding-left: 10px;
  color: #fff;
}

::v-deep .keyinput .el-input__wrapper {
  background-color: initial;
  box-shadow: none;
  border-radius: 8px;
}

.scrollbardiv {
  &::-webkit-scrollbar {
    width: 6px;
    height: 8px;
  }

  // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
  &::-webkit-scrollbar-button {
    display: none;
  }

  // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
  &::-webkit-scrollbar-thumb {
    background: rgba(144, 147, 153, 0.3);
    cursor: pointer;
    border-radius: 4px;
  }

  // 边角，即两个滚动条的交汇处
  &::-webkit-scrollbar-corner {
    display: none;
  }

  // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
  &::-webkit-resizer {
    display: none;
  }
}
</style>
  
   