<template>
  <div v-clickOutside="handleHide"  class="wrapper" v-if="visible">
    <Midmenuchat :isdark="true" :ismenu="false" />
    <div class="header">
      <i className="back-icon iconfont icon-fanhui" @click="handleClose"></i>
      <span>聊天机器人</span>
    </div>
    
  </div>
</template>
<script setup>
import { computed, defineEmits, onMounted } from 'vue';
import { useStore } from 'vuex';
import { ClickOutside as vClickOutside } from 'element-plus'
import Midmenuchat from "@/components/Midmenuchat.vue";
import emitter from "@/utils/eventBus";

const store = useStore();
const emit = defineEmits(['changeVisible']);
const visible = computed(() => store.state.videoSetting.chatRobotVisible);
const handleClose = (parentShow = true) => {
  store.commit('videoSetting/updateChatRobot', false);
}
const handleHide = (parentShow = true) => {
  store.commit('videoSetting/updateChatRobot', false);
}
onMounted(() => {
  // 初始化主题色
  window.document.documentElement.setAttribute("data-theme", "dark");
  emitter.emit("selecTypechat", {
    avatar:
      "https://oss.onechatai.cn/upload/avatar/e856068a-d320-11ee-8b90-5254001b1865.jpeg",
    class: 7,
    default: false,
    desc: "测试1日团团圆圆一",
    hint: "",
    id: 584,
    mark: "",
    phone: "",
    pid: 1,
    pinyin: "gugejiqiren",
    prompt: "测试一下就会了我想你啦",
    status: 1,
    title: "单词机器人",
    type: 9,
    extraInfo: { word:'' },
    userinfo: {},
  });
})



</script>

<style lang="scss">
.wrapper {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 50px;
  width: 340px;
  background: var(--181a20);
  border-radius: 4px;
  color: var(--fff05);
  font-size: 14px;
  display: flex;
  flex-direction: column;
  .midtopmenu{
    padding:0;
    .topborder{
      visibility: hidden
    }
    .midtopdiv{
      border-radius:0;
    }
  }

  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    line-height: 60px;
    color: var(--fff);
    font-size: 20px;
    font-weight: 500;
    padding: 0 20px;
    border-bottom: 1px solid var(--2f3036);
    box-sizing: border-box;

    .back-icon {
      font-size: 20px;
      margin-right: 12px;
      cursor: pointer;
    }
  }

  .content {
    flex: 1;
    overflow-y: scroll;
    padding: 16px 20px;

    .shortcuts {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid var(--2f3036);
      border-bottom: none;
      height: 36px;
      box-sizing: border-box;
      padding: 0 12px;

      &.bottom-border {
        border-bottom: 1px solid var(--2f3036);
      }

      .icon {
        height: 20px;
        line-height: 20px;
        padding: 0 4px;
        border: 1px solid var(--2f3036);
        box-sizing: border-box;

        &.padding2 {
          padding: 0 2px;
        }
      }
    }

    .split-box {
      height: 16px;
    }

    .practice-mode-title {
      line-height: 60px;
      font-weight: 500;
      color: var(--fff05)
    }
  }
}
</style>