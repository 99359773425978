<template>
  <div style="position: relative; width: 100%; height: 100%">
    <div class="word-list-panel" :style="showLeft ? `width:300px` : 'width:0'">
      <div class="collect-btn" @click="() => (showLeft = !showLeft)">
        <span class="icon iconfont">&#xe66e;</span>
      </div>
      <div class="content" v-show="showLeft">
        <div class="list-title">我的单词列表</div>
        <ul class="filter-panel">
          <li v-for="item in filterList" :key="item.key" @click="currentFilter = item.key"
            :class="item.key === currentFilter ? 'active' : ''">
            {{ item.value }}
          </li>
        </ul>
        <ul class="list" v-infinite-scroll="debounce(getWordsList, 1000)" infinite-scroll-distance="60"
          :infinite-scroll-disabled="noMoreData">
          <li class="list-item" v-for="item in wordList" :key="item.id" @click="handleClickWord(item.words_list)">
            <p class="word">{{ item.words_list }}</p>
            <p class="translate">{{ item.translate_words }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="mindmap">
      <div class="content">
        <MindContent :word="word" @clickVideo="handleClickVideo" />
      </div>
    </div>
    <div class="right-div" :style="showRight ? `width:300px` : 'width:0'">
      <div class="collect-btn" @click="() => (showRight = !showRight)">
        <span class="icon iconfont" style="transform: rotate(180deg)">&#xe66e;</span>
      </div>
      <div v-show="showRight" style="width: 100%; height: 100%">
        <Midmenuchat :isdark="isdark" :ismenu="ismenu" @changeismenu="changeismenu" />
      </div>
    </div>
    <el-dialog v-model="showVideoPlay" center modal-class="video-drawer" :show-close="false" width="645">
      <template v-slot:header></template>
      <div style="width:645px;height: 486px">
        <Player v-if="showVideoPlay" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Leftmenu from "../components/LeftMenu2.vue";
import Midmenuchat from "../components/Midmenuchat.vue";
import MindContent from "../components/MindContent2.vue";
import api from "../api/video";
import emitter from "../utils/eventBus";
import Player from "../components/VideoPlayer.vue";
import store from "../store";
import { ModeType } from "@/contants/playState";
import debounce from "lodash/debounce";
export default {
  name: "App",
  components: {
    Leftmenu,
    Midmenuchat,
    MindContent,
    Player,
  },
  data() {
    return {
      screenWidth: document.documentElement.clientWidth,
      isphone: false, //是否是手机
      isdark: false, //是否暗色主题
      ismenu: true,
      wordList: [],
      word: this.$route.params.word,
      showLeft: true,
      showRight: true,
      page: 1,
      noMoreData: false,
      showVideoPlay: false,
      filterList: [
        { key: "all", value: "全部" },
        { key: "is_like", value: "收藏" },
        { key: "master", value: "掌握" },
        { key: "all_master", value: "完全掌握" },
      ],
      currentFilter: "all",
    };
  },
  mounted() {
    // 初始化主题色
    window.document.documentElement.setAttribute("data-theme", "dark");
    emitter.emit("selecTypechat", {
      avatar:
        "https://oss.onechatai.cn/upload/avatar/e856068a-d320-11ee-8b90-5254001b1865.jpeg",
      class: 7,
      default: false,
      desc: "测试1日团团圆圆一",
      hint: "",
      id: 584,
      mark: "",
      phone: "",
      pid: 1,
      pinyin: "gugejiqiren",
      prompt: "测试一下就会了我想你啦",
      status: 1,
      title: "单词机器人",
      type: 9,
      extraInfo: { word: this.$route.params.word },
      userinfo: {},
    });
  },
  methods: {
    debounce,
    handleClickVideo(videoInfo) {
      const { video_url, endTime, startTime } = videoInfo;
      store.commit("videoPlayer/changeVideoInfo", { video_url, startTime });
      store.commit("subtitles/updateCurSubTitle", { endTime, startTime });
      store.commit("videoPlayer/changeModeType", ModeType.single);
      this.showVideoPlay = true;
    },
    changeismenu(flag) {
      this.ismenu = flag;
      console.log(this.ismenu);
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    async getWordsList(val = this.currentFilter) {
      console.log(`>>>>到底啦`);
      const res = await api.aiVideoWordsList({
        video_id: 1,
        prompts_id: 0,
        page: this.page,
        pageSize: 25,
        order: val === "all" ? "" : val,
      });
      this.wordList = this.wordList.concat(res.data.msg);
      if (res.data.msg.length < 25) this.noMoreData = true;
      this.page++;
    },
    getDark(isdark) {
      this.isdark = isdark;
    },
    handleClickWord(word) {
      this.$router.replace({
        name: this.$route.name,
        params: { word },
      });
      this.word = word;
    },
  },
  watch: {
    currentFilter(val) {
      this.page = 1;
      this.wordList = [];
      this.noMoreData = false;
      debounce(this.getWordsList(val), 1000);
    },
  },
};
</script>

<style lang="scss">
.word-list-panel {
  position: absolute;
  transition: width 0.2s;
  left: 0;
  bottom: 0;
  top: 0;
  width: 300px;
  z-index: 1;

  .collect-btn {
    width: 30px;
    height: 30px;
    background: linear-gradient(90deg, transparent 30%, #1f222a 30%);
    position: absolute;
    right: -20px;
    top: 100px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 5px;
    color: #fff;
    cursor: pointer;
  }

  .content {
    background-color: rgb(31, 34, 42);
    border-radius: 8px 0 0 8px;
    height: 100%;
  }

  .list-title {
    font-size: 20px;
    padding: 16px 35px;
    color: #ccc;
    border-bottom: 1px solid #2f3036;
  }

  .list {
    height: calc(100% - 60px);
    overflow: auto;
  }

  .list-item {
    padding: 8px 20px;
    border-bottom: 1px solid #2f3036;

    &:hover {
      background: rgba(0, 0, 0, 0.05);
      cursor: pointer;
    }

    .word {
      font-size: 20px;
      color: #fff;
      line-height: 28px;
      margin-bottom: 4px;
    }

    .translate {
      font-size: 12px;
      color: #ccc;
    }
  }
}

.mindmap {
  float: left;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg,
      rgba(200, 200, 200, 0.02) 3%,
      transparent 0),
    linear-gradient(rgba(200, 200, 200, 0.02) 3%, transparent 0);
  background-size: 60px 60px;

  .content {
    width: 100%;
    height: 100%;
  }
}

.video-drawer {
  .el-dialog__header {
    display: none;
  }

  .el-dialog__body {
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
  }
}
</style>
<style scoped lang="scss">
.wrapper {
  background-color: #111819;
}

.filter-panel {
  display: flex;
  background: #181a20;
  border-radius: 7px;
  margin-top: 16px;
  margin-bottom: 16px;
  margin-left: 20px;
  margin-right: 20px;
  justify-content: space-around;
  align-items: center;
  height: 30px;
  color: #ccc;
  padding: 3px;
  cursor: pointer;

  li.active {
    background: rgba(255, 255, 255, 0.1);
    display: inline-block;
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 0 6px;
  }
}

.right-div {
  position: absolute;
  transition: 0.2s;
  height: 100%;
  right: 0;
  width: 300px;
  z-index: 1;

  .collect-btn {
    width: 30px;
    height: 30px;
    background: linear-gradient(260deg, transparent 30%, #1f222a 30%);
    position: absolute;
    left: -20px;
    top: 100px;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 5px;
    color: #fff;
    cursor: pointer;
  }

  ::v-deep {
    .openmidtopmenu {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
      padding: 0;
    }

    .midtopdiv {
      border: none;
      border-radius: 0 8px 8px 0;
    }
  }
}
</style>