<template>
  <div class="control-bar">
    <div class="progress-outside">
      <div :class="`video-preview ${preViewInfo.show ? 'show' : ''}`" :style="`left:${preViewInfo.left}%`">
        <img class="thumb" :src="curPreViewImage" />
      </div>
      <div v-if="playState !== PLAY_STATE.loading" ref="progressRef" class="progress-bar" @click="handleSeek"
        @mousemove="handleMove" @mouseout="handleMoveOut">
        <div class="progress" :style="{ width: `${(currentTime / player?.duration) * 100}%` }"></div>
        <div class="buffer-progress" :style="{ width: `${(bufferTime / player?.duration) * 100}%` }"></div>
      </div>
    </div>
    <div v-if="visibleControlBar" class="control-bar-panel">
      <div class="video-info" v-if="props.page !== 'practice'">
        <div class="video-cover"></div>
        <div>
          <p class="video-title">{{ currentVideoInfo.video_name }}</p>
          <p class="author">{{ currentVideoInfo.author }}</p>
          <div class="video-mode">
            <el-popover placement="top-start" effect="dark" :show-arrow="false" trigger="click"
              popper-class="poppperBox" popper-style="width: 80px" :disabled="isChildProduct">
              <template #reference>
                <li style="
                  display: flex;
                  align-items: center;
                  margin-top: 4px;
                  color: #ccc;
                ">
                  {{ practiceMode.label }}
                  <i class="iconfont icon-xialajiantou" style="font-size: 18px"></i>
                </li>
              </template>
              <ul class="popList practice-mode-list">
                <li v-for="item in PRACTICE_MODES" :key="item.value" @click="(ev) => handleChangePracticeMode(item)"
                  :class="{ checked: practiceMode.value === item.value }">
                  <span v-if="practiceMode.value === item.value"><img src="../../assets/checked.svg" /></span>
                  {{ item.label }}
                </li>
              </ul>
            </el-popover>
          </div>
        </div>
        <hover-tip text="收藏视频">
          <div class="collect mobile-hide" @click="handleCollect">
            <i v-if="!currentVideoInfo.is_like" className="iconfont icon-shoucang-1" />
            <i v-else className="iconfont icon-shoucang-2" style="color: var(--primary)"></i>
          </div>
        </hover-tip>
      </div>
      <div class="video-info video-info-practice" v-else>
        <div class="video-cover">
          <i :class="`iconfont ${practiceMode.icon}`"></i>
        </div>
        <div class="video-mode">
          <span style="color: #999">模式选择</span>
          <el-popover placement="top-start" effect="dark" :show-arrow="false" trigger="click" popper-class="poppperBox"
            popper-style="width: 80px" :disabled="isChildProduct">
            <template #reference>
              <li style="
                  display: flex;
                  align-items: center;
                  margin-top: 4px;
                  color: #ccc;
                ">
                {{ practiceMode.label }}
                <i class="iconfont icon-xialajiantou" style="font-size: 18px"></i>
              </li>
            </template>
            <ul class="popList practice-mode-list">
              <li v-for="item in PRACTICE_MODES" :key="item.value" @click="(ev) => handleChangePracticeMode(item)"
                :class="{ checked: practiceMode.value === item.value }">
                <span v-if="practiceMode.value === item.value"><img src="../../assets/checked.svg" /></span>
                {{ item.label }}
              </li>
            </ul>
          </el-popover>
        </div>
        <!-- 正确率 & 正确数 -->
        <!-- <div
          class="evaluate-result"
          v-if="(practiceMode.mode !== 1) & (practiceMode.mode !== 2)"
        >
          <div><span>正确率</span><span>33%</span></div>
          <div><span>正确率</span><span>4</span></div>
        </div> -->
      </div>
      <div class="main-control">
        <ul class="operation-group">
          <el-popover placement="top-start" effect="dark" :show-arrow="false" trigger="click" popper-class="poppperBox"
            popper-style="width: 80px">
            <template #reference>
              <li style="
                  display: flex;
                  align-items: center;
                  margin-top: 4px;
                  color: #ccc;
                ">
                <i :class="['iconfont', currentMode.icon]" style="font-size: 18px"></i>
              </li>
            </template>
            <ul class="popList practice-mode-list">
              <li v-for="item in ModeTypeLocal" :key="item.value" @click="(ev) => handleChangeMode(item.value)"
                :class="{ checked: +mode === +item.value }">
                <span v-if="+mode === +item.value"><img src="../../assets/checked.svg" /></span>
                {{ item.label }}
              </li>
            </ul>
          </el-popover>
          <hover-tip text="上一句">
            <li @click="handlePre">
              <i className="iconfont icon-shangyige" style="font-size: 24px" />
            </li>
          </hover-tip>
          <li @click="handleClickPlay" v-if="playState === PLAY_STATE.loading">
            加载中
          </li>
          <li @click="handleClickPlay" v-if="
            playState === PLAY_STATE.paused || playState === PLAY_STATE.loaded
          " class="play-btn">
            <i class="iconfont icon-kaishi" style="font-size: 36px"></i>
          </li>
          <li @click="handleClickPause" v-if="playState === PLAY_STATE.play">
            <i class="iconfont icon-zanting1" style="font-size: 36px"></i>
          </li>
          <li @click="handleClickReplay" v-if="playState === PLAY_STATE.ended">
            <i class="iconfont icon-kaishi" style="font-size: 36px"></i>
          </li>
          <hover-tip text="下一句">
            <li @click="handleNext">
              <i className="iconfont icon-xiayige" style="font-size: 24px" />
            </li>
          </hover-tip>
          <el-popover placement="top-start" effect="dark" :show-arrow="false" trigger="click" popper-class="poppperBox">
            <template #reference>
              <div>
                <hover-tip text="倍速">
                  <li>
                    <i class="iconfont icon-beisu" style="font-size: 18px"></i>
                  </li>
                </hover-tip>
              </div>
            </template>
            <ul class="popList">
              <li v-for="key in playbackRate" :key="key" @click="(ev) => handleChangeRate(ev, key)"
                :class="{ checked: playRate === key }">
                <span v-if="playRate === key"><img src="../../assets/checked.svg" /></span>
                {{ key }}x
              </li>
            </ul>
          </el-popover>
        </ul>
      </div>
      <div v-if="props.page !== 'practice'" class="right-control">
        <ul class="operation-group">
          <li @click="handleChangePracticeMode({ label: '跟读模式', value: 6, icon: 'icon-tingxie' })" class="mobile-hide">
            <hover-tip text="跟读模式">
              <i class="iconfont icon-tiankongtishi-"></i>
            </hover-tip>
          </li>
          <li @click="handleChatRobot" class="mobile-hide">
            <hover-tip text="聊天机器人">
              <i class="iconfont icon-ai"></i>
            </hover-tip>
          </li>
          <el-popover placement="top-start" effect="dark" :show-arrow="false" trigger="click" popper-class="poppperBox">
            <template #reference>
              <div>
                <hover-tip text="字幕">
                  <li v-if="currentSubTitle === 4">
                    <i class="iconfont icon-danmu-" style="font-size: 22px"></i>
                  </li>
                  <li v-else><i class="iconfont icon-Union"></i></li>
                </hover-tip>
              </div>
            </template>
            <ul class="popList">
              <li v-for="item in SUBTITLE_TYPES" :key="item.value" @click="handleChangeSubTitle(item)"
                :class="{ checked: currentSubTitle === item.value }">
                <span v-if="currentSubTitle === item.value"><img src="../../assets/checked.svg" /></span>
                {{ item.label }}
              </li>
            </ul>
          </el-popover>
          <li v-if="!fullscreen" @click="handleFullScreen" class="mobile-hide">
            <i class="iconfont icon-Vector-4"></i>
          </li>
          <li v-if="fullscreen" @click="handleExitFullScreen" class="mobile-hide">
            <i class="iconfont icon-quanping-"></i>
          </li>
          <!-- 视频设置 -->
          <li class="mobile-hide">
            <VideoSetting ref="videoSettingRef"></VideoSetting>
          </li>
        </ul>
      </div>
      <div v-else class="right-control mobile-hide">
        <ul class="operation-group" style="grid-gap: 26px">
          <li @click="handleChangePracticeMode({ label: '阅读模式', value: 'read', icon: 'icon-Vector-2' })"
            class="mobile-hide">
            <hover-tip text="跟读模式">
              <i class="iconfont icon-tiankongtishi-  active"></i>
            </hover-tip>
          </li>
          <!-- ai -->
          <li @click="handleChatRobot">
            <HoverTip text="聊天机器人">
              <i :class="`iconfont ${practice_controls.ai ? 'icon-ai active' : 'icon-ai'
                }`"></i>
            </HoverTip>
          </li>
          <!-- 单词可见 -->
          <li @click="
            handleChangePracticeControl('visible', !practice_controls.visible)
            ">
            <i :class="`iconfont ${!practice_controls.visible
              ? 'icon-bukejian active'
              : 'icon-kejian'
              }`"></i>
          </li>
          <!-- 翻译 -->
          <li @click="
            handleChangePracticeControl(
              'translate',
              !practice_controls.translate
            )
            ">
            <i :class="`iconfont ${practice_controls.translate
              ? 'icon-fanyi active'
              : 'icon-fanyi-'
              }`"></i>
          </li>
          <!-- 缩放 -->
          <li @click="
            handleChangePracticeControl('scale', !practice_controls.scale)
            ">
            <i :class="`iconfont ${practice_controls.scale
              ? 'icon-shipinzuoshangjiao active'
              : 'icon-suoxiaoshipin'
              }`"></i>
          </li>
          <!-- 全屏 -->
          <li @click="fullscreen ? handleExitFullScreen() : handleFullScreen()">
            <i :class="`iconfont ${fullscreen ? 'icon-quanping- active' : 'icon-Vector-4'
              }`"></i>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  ref,
  reactive,
  defineProps,
  defineEmits,
  watch,
  onMounted,
  onUnmounted,
} from "vue";
import { useStore } from "vuex";
import PLAY_STATE, {
  PRACTICE_MODES,
  SUBTITLE_TYPES,
  ModeType,
  playbackRate,
  ModeTypeLocal,
} from "@/contants/playState";
import HoverTip from "@/components/HoverTip";
import router from "@/router";
import emitter from "@/utils/eventBus";
import VideoSetting from "@/components/VideoSetting";
import videoApi from "@/api/video";
import lodash from "lodash";
import { Events } from "xgplayer";
import { EVENTKeys, EVENTKeys_PRATICE, EVENTKeys_SUBTITLES } from "@/contants/eventkeys";
import { ElMessage } from "../MyMessage";

const store = useStore();

const props = defineProps({
  page: String,
});
const emit = defineEmits(["changePage"]);

const mode = computed(() => store.state.videoPlayer.modeType);
const video_id = router.currentRoute.value.query.video_id;
const currentVideoInfo = computed(
  () => store.state.videoPlayer.currentVideoInfo
);
const currentTime = computed(() => store.state.videoPlayer.currentTime);
const bufferTime = computed(() => store.state.videoPlayer.bufferTime);
const playState = computed(() => store.state.videoPlayer.playState);
const playRate = computed(() => store.state.videoPlayer.playRate);
const player = computed(() => store.state.videoPlayer.player);
const currentMode = computed(() => ModeTypeLocal.find(item => +item.value === +mode.value), mode.value)
const hiddenControlBar = computed(
  () => store.state.videoSetting.settings.hiddenControlBar
);
const visibleControlBar = ref(true);
const progressRef = ref();
const fullscreen = ref(false);
const videoSettingRef = ref(null);
const preViewInfo = ref({});
const preViewImageList = ref([]);
const curPreViewImage = ref("");
const isChildProduct = ref(window.productType === "child");
// TODO 全屏切换后视频大小有点问题
const handleKeydown = (event) => {
  // 以下按照Mac的键盘配置开发，Win的未测试
  // console.log("%c Line:377 🍭", "color:#ea7e5c", event);

  const eventFn = EVENTKeys.find(item => item.keyCode === event.keyCode) || EVENTKeys_PRATICE.find(item => item.keyCode === event.keyCode) || EVENTKeys_SUBTITLES.find(item => item.keyCode === event.keyCode);
  if (eventFn) {
    ElMessage({
      message: eventFn.chinese
    })
  }
  // 加速播放 S
  if (event.keyCode === 83) {
    let playRateIdx = playbackRate.findIndex((rate) => rate === playRate.value);
    if (playRateIdx === playbackRate.length - 1) return;
    player.value.playbackRate = playbackRate[playRateIdx + 1];
  }

  // 减速播放 D
  if (event.keyCode === 68) {
    let playRateIdx = playbackRate.findIndex((rate) => rate === playRate.value);
    if (playRateIdx === 0) return;
    player.value.playbackRate = playbackRate[playRateIdx - 1];
  }

  // 切换剧场/阅读模式 M
  if (event.keyCode === 77) {
    let pageName = props.page === "read" ? "theater" : "read";
    return changePageMode(pageName);
  }

  // 练习模式 P
  if (event.keyCode === 80) return changePageMode("practice");

  // 查看快捷键 ?
  if (event.shiftKey && event.keyCode === 191) {
    let quickKeyBoard = store.state.videoSetting.keyboard;
    store.commit("videoSetting/updateKeyboard", !quickKeyBoard);
    return;
  }

  // 开启/关闭视频全屏 option + W /  option + F
  if (
    (event.altKey && event.keyCode === 87) ||
    (event.altKey && event.keyCode === 70)
  ) {
    if (fullscreen.value) return handleExitFullScreen();
    return handleFullScreen();
  }

  // 练习模式
  if (props.page === "practice") {
    // 显示/隐藏视频 option + V
    if (event.altKey && event.keyCode === 86)
      return handleChangePracticeControl("scale", !practice_controls.scale);

    // 显示/隐藏翻译 option + Z
    if (event.altKey && event.keyCode === 90)
      return handleChangePracticeControl(
        "translate",
        !practice_controls.translate
      );

    // 显示/隐藏提示 option + C
    if (event.altKey && event.keyCode === 67)
      return handleChangePracticeControl("tip", !practice_controls.tip);

    // 显示/隐藏单词 option + A
    if (event.altKey && event.keyCode === 65)
      return handleChangePracticeControl("visible", !practice_controls.visible);
  }
};

// 隐藏控制栏
let prevY = 0;
const handleMouseMove = lodash.throttle((event) => {
  const currentY = event.clientY;
  const deltaY = currentY - prevY;

  if (deltaY > 0 && currentY > window.innerHeight - 80) {
    // 向下移动且鼠标位于屏幕底部附近
    if (!hiddenControlBar.value) return;
    if (!visibleControlBar.value) visibleControlBar.value = true;
  } else {
    if (!hiddenControlBar.value || store.state.videoSetting.visible) return;
    if (visibleControlBar.value) visibleControlBar.value = false;
  }
}, 500);

onMounted(() => {
  visibleControlBar.value = !hiddenControlBar.value;
  window.addEventListener("keydown", handleKeydown);
  window.addEventListener("mousemove", handleMouseMove);
  // getPreviewImage();
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleKeydown);
  window.removeEventListener("mousemove", handleMouseMove);
});

const handleSeek = (e) =>
(player.value.currentTime =
  (e.offsetX / progressRef.value.clientWidth) * player.value.duration);
const handleClickPlay = () => player.value.play();
const handleClickPause = () => player.value.pause();
const handleClickReplay = () => player.value.rePlay();
const handleChangeRate = (e, rate) => {
  const props = {
    playbackRate: {
      from: player.value.playbackRate,
      to: rate,
    },
  };
  player.value.emitUserAction(e, "change_rate", {
    props,
    pluginName: "playbackrate",
  });
  player.value.playbackRate = rate;
};

const handleMove = (e) => {
  preViewInfo.value = {
    left: Math.max(
      Math.min(
        Math.ceil((e.offsetX / progressRef.value.clientWidth) * 100),
        95
      ),
      5
    ),
    show: true,
  };
  const second =
    (e.offsetX / progressRef.value.clientWidth) * player.value.duration;
  curPreViewImage.value = `${currentVideoInfo.value.video_url}?vframe/jpg/offset/${second}`;
};

const handleMoveOut = (e) => {
  preViewInfo.value = {
    ...preViewInfo.value,
    show: false,
  };
};

const handleFullScreen = () => {
  document.body.requestFullscreen();
  fullscreen.value = true;
};
const handleChatRobot = () => {
  store.commit("videoSetting/updateChatRobot", true);
  store.commit("videoSetting/updateSettingVisible", false);
};
const handleExitFullScreen = () => {
  document.exitFullscreen();
  fullscreen.value = false;
};

const changeCurrent = (getTime) => {
  if (!player.value) return;
  const isPlaying = player.value.isPlaying;
  const _cur = store.state.subtitles.curSubtitle;
  const time = getTime(_cur);
  if (!time) return;
  new Promise((resolve) => {
    if (isPlaying) {
      handleClickPause();
      let timer = setTimeout(() => {
        clearTimeout(timer);
        resolve();
      }, 500);
    } else {
      resolve();
    }
  }).then(() => {
    emitter.emit(Events.TIME_UPDATE, time);
    player.value.currentTime = time;
  });
};
const handlePre = () => changeCurrent((_cur) => _cur?.pre.startTime);
const handleNext = () => changeCurrent((_cur) => _cur?.next.startTime);

emitter.on("player-click-pre", handlePre);
emitter.on("player-click-next", handleNext);
emitter.on("player-click-play", handleClickPlay);
emitter.on("player-click-pause", handleClickPause);
// 播放模式切换
const handleChangeMode = (val) => {
  if (props.page === "practice") return; // 训练模式不支持调整循环模式
  localStorage.setItem("userCheckedMode", val);
  store.commit("videoPlayer/changeModeType", val);
};

const gotologin = () => {
  this.$router.push({
    name: "Vlogin",
    query: {
      redirect: encodeURI(this.$route.fullPath),
    },
  });
}
emitter.on("gotologin", gotologin);
// 切换字幕
const currentSubTitle = computed(() => store.state.subtitles.subtitleType);
const handleChangeSubTitle = (item) =>
  store.commit("subtitles/updateSubtitleType", item.value);

// initSentence 针对于练习模式，如果没有当前字幕，则默认第一句为当前字幕
const initSentence = () => {
  changeCurrent((_cur) => {
    if (!_cur) _cur = store.state.subtitles.list[0];
    return _cur.startTime;
  });
};
emitter.on("init-sentence", initSentence);
// 视频模式切换
const changePageMode = (pageName) => {
  if (pageName === "practice") {
    store.commit("videoPlayer/changeModeType", ModeType.singleOnce); // 练习模式只使用单句播放模式
    initSentence();
  }
  emit("changePage", pageName);
};

// 视频收藏
const handleCollect = async () => {
  const res = await videoApi.collectVideo(
    video_id,
    currentVideoInfo.value.is_like ? 2 : 1
  );
  if (res.code !== 0) return;
  store.commit("videoPlayer/setVideoInfo", {
    is_like: !currentVideoInfo.value.is_like,
  });
};

// controlbar左侧 模式切换
const defaultPracticeMode = computed(() => {
  const mode = router.currentRoute.value.params.childPageMode;
  return (
    PRACTICE_MODES.find((item) => item.value === +mode) ||
    PRACTICE_MODES[isChildProduct.value ? 5 : 0]
  );
});
const practiceMode = ref(defaultPracticeMode.value);
const handleChangePracticeMode = (data) => {
  if (data.value === 'theater') {
    if (props.page !== 'theater') {
      changePageMode("theater")
    }
  } else if (data.value === 'read') {
    if (props.page !== 'read') {
      changePageMode("read")
    }
  } else {
    if (props.page !== 'practice') {
      changePageMode("practice")
      const timer = setTimeout(() => {
        clearTimeout(timer);
        emitter.emit("practice-mode-change", data);
      }, 0);
    } else {
      emitter.emit("practice-mode-change", data);
    }
  }
  practiceMode.value = data;
};
handleChangePracticeMode(defaultPracticeMode.value);

// controlbar右侧 功能切换
const practice_controls = reactive({
  ai: false,
  visible: false,
  translate: false,
  tip: false,
  scale: true,
});

const handleChangePracticeControl = (tag, val) => {
  practice_controls[tag] = val;
  emitter.emit(`practice-player-${tag}`, val);
};
</script>

<style lang="scss" scoped>
.control-bar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  // height: 80px;
  padding-bottom: 10px;
  box-sizing: border-box;
  background: var(--page-bg-color);
  display: flex;
  flex-direction: column;

  .iconfont {
    color: #999;
    font-size: 18px;
    cursor: pointer;

    &:hover {
      color: var(--fff);
    }
  }

  [data-theme="light"] & .play-btn .iconfont,
  [data-theme="light"] & .iconfont:hover {
    color: var(--primary);
  }
}

.progress-outside {
  padding-bottom: 10px;
  width: 100%;
  position: relative;

  &:hover {
    .progress-bar {
      cursor: pointer;
      transform: scaleY(2);

      .progress::after {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%) scaleY(0.5);
        content: "";
        width: 10px;
        height: 10px;
        background: var(--primary);
        border-radius: 10px;
      }
    }
  }

  .video-preview {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    position: absolute;
    bottom: 20px;
    transform: translateX(-90px);
    transition: opacity 0.2s ease 0s;

    &.show {
      opacity: 1;
    }

    .thumb {
      width: 158px;
      height: 90px;
      box-sizing: border-box;
      border-radius: 10px;
      // border: 2px solid rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 0px 2px rgba(255, 255, 255, 0.06);
    }
  }

  .progress-bar {
    width: 100%;
    height: 2px;
    background: var(--ffffff20);
    border-radius: 8px;

    .progress {
      position: absolute;
      // width: 30px;
      height: inherit;
      background: var(--ffffff20);
      border-radius: 4px;
      transition: height 100ms ease-out;
      transform-origin: bottom;
    }

    .buffer-progress {
      position: absolute;
      height: inherit;
      background: var(--primary20);
      border-radius: 4px;
      transition: height 100ms ease-out;
      transform-origin: bottom;
    }
  }
}

.control-bar-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #999;
  font-size: 12px;
  padding: 0 20px;
  user-select: none;
  -webkit-user-drag: none;
}

[data-platform="mobile"] .control-bar-panel {
  padding: 0 10px 40px 10px;
}

.video-info {
  display: flex;
  grid-gap: 12px;
  align-items: center;

  &-practice {
    .video-cover {
      text-align: center;
      flex: none;
    }
  }

  .evaluate-result {
    display: flex;
    align-items: center;

    div {
      width: 100px;
      font-size: 12px;
      text-align: center;

      span {
        display: block;
        font-size: 16px;
        color: #ccc;

        &:first-of-type {
          color: #999;
          font-size: 12px;
          margin-bottom: 4px;
        }
      }
    }
  }

  .video-cover {
    width: 48px;
    height: 48px;
    border-radius: 8px;
    border: 1px solid #333;
    flex: none;

    .iconfont {
      color: var(--fff);
      font-size: 24px;
      margin: auto;
      line-height: 48px;
    }
  }

  [data-theme="light"] & .video-cover {
    border: 1px solid rgba(0, 0, 0, 0.2);

    .iconfont {
      color: #999;
    }
  }
}

[data-platform="mobile"] .mobile-hide {
  display: none;
}

.operation-group {
  display: flex;
  align-items: center;
  list-style: none;
  grid-gap: 32px;

  li {
    cursor: pointer;

    &:hover {
      color: var(--fff);
    }
  }

  .iconfont.active {
    color: #fbd026;
  }
}

[data-platform="mobile"] .operation-group {
  justify-content: space-around;
}

[data-platform="pc"] .operation-group {
  justify-content: space-between;
}

.popList {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 8px;

  li {
    text-align: center;
    // width: 111px;
    // height: 40px;
    line-height: 30px;
    font-size: 14px;
    color: var(--fff);
    user-select: none;
    margin: 0 auto;
    cursor: pointer;
    border-radius: 4px;

    &:hover,
    &.checked {
      background: var(--1f222a);
      position: relative;

      img {
        position: absolute;
        left: 4px;
        top: 50%;
        transform: translateY(-50%);
      }

      [data-theme="light"] & img {
        background: var(--fff);
        border-radius: 4px;
      }
    }
  }
}

.popList+.popList {
  border-top: 1px solid var(--2f3036);
  padding-top: 8px;
}

.collect {
  cursor: pointer;
}

[data-platform="mobile"] .main-control {
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
}
</style>
<style lang="scss">
.el-popover.el-popper.is-dark {
  padding: 0;
  background: var(--181a20);
  border: 1px solid var(--1f222a);
  border-radius: 20px;
  min-width: auto;
  // width: 127px !important;
  padding: 8px;
  min-width: 127px !important;
}

.poppperBox {
  transform: translateX(-50px);
}

.practice-mode-list {
  width: 111px;
}
</style>
