<template>
  <div :id="videoId"></div>
</template>

<script setup>
import PLAY_STATE, { ModeType, playbackRate } from "../contants/playState";
import Player, { Events } from "xgplayer";
import "xgplayer/dist/index.min.css";
import { computed, onMounted, defineProps } from "vue";
import { useStore } from "vuex";
import emitter from "@/utils/eventBus";

const store = useStore();
const currentVideoInfo = computed(
  () => store.state.videoPlayer.currentVideoInfo
);
const CurSubTitle = computed(() => store.state.subtitles.curSubtitle);
const subtitleList = computed(()=>store.state.subtitles.list);

const videoId = "mse";
const props = defineProps({
  width: String,
});
const modeType = computed(() => +store.state.videoPlayer.modeType);
onMounted(() => {
  const container = document.getElementById(videoId);
  if (!container) return;
  const player = new Player({
    id: videoId,
    url: currentVideoInfo.value.video_url,
    poster: currentVideoInfo.value.video_img,
    fitVideoSize: "auto",
    defaultPlaybackRate: 1,
    controls: false,
    height: "100%",
    width: "100%",
    enableContextmenu:false
  });
  if (currentVideoInfo.value.startTime) {
    const timer = setTimeout(() => {
      player.currentTime = currentVideoInfo.value.startTime;
      clearTimeout(timer);
    }, 0);
  }
  store.commit("videoPlayer/setPlayer", player);
  player.on(Events.LOAD_START, () =>
    store.commit("videoPlayer/changePlayState", PLAY_STATE.loading)
  );
  player.on(Events.LOADED_DATA, () =>
    store.commit("videoPlayer/changePlayState", PLAY_STATE.loaded)
  );
  player.on(Events.PLAY, () =>
    store.commit("videoPlayer/changePlayState", PLAY_STATE.play)
  );
  player.on(Events.PAUSE, () =>
    store.commit("videoPlayer/changePlayState", PLAY_STATE.paused)
  );
  player.on(Events.ENDED, () =>
    store.commit("videoPlayer/changePlayState", PLAY_STATE.ended)
  );
  player.on(Events.RATE_CHANGE, () =>
    store.commit("videoPlayer/changePlayRate", player.playbackRate)
  );
  player.on(Events.ERROR, (error) => {
    player.pause();
    console.log(error);
  });
  player.on(Events.TIME_UPDATE, () => {
    if (CurSubTitle.value) {
      if (modeType.value === ModeType.single) {
        if (player.currentTime > CurSubTitle.value.endTime) {
          player.pause();
          player.currentTime = CurSubTitle.value.startTime;
          const timer = setTimeout(() => {
            player.play();
            clearTimeout(timer);
          }, 0);
        }
      } else if (modeType.value === ModeType.singleOnce) {
        if (player.currentTime > CurSubTitle.value.endTime) {
          player.pause();
          player.currentTime = CurSubTitle.value.startTime;
        }
      } else if (modeType.value === ModeType.singleChange) {
        if (player.currentTime > CurSubTitle.value.endTime) {
          player.pause();
          player.currentTime = CurSubTitle.value.startTime;
          const playRateIdx = playbackRate.findIndex(
            (item) => item === player.playbackRate
          );
          player.playbackRate =
            playbackRate[(playRateIdx + 1) % playbackRate.length];
          const timer = setTimeout(() => {
            player.play();
            clearTimeout(timer);
          }, 0);
        }
      } else {
        emitter.emit(Events.TIME_UPDATE, player.currentTime);
      }
    } else {
      const cursub = subtitleList.value.find(item => item.startTime < player.currentTime && player.currentTime < item.endTime);
      if (cursub) {
        store.commit("subtitles/updateCurSubTitle", cursub);
      }
    }
  });
  player.on(Events.PROGRESS, () => {
    store.commit("videoPlayer/changeBufferTime", player.getBufferedRange()[1]);
  });
});
</script>