import axios from '../utils/request.js'
import { parseVideoInfo } from './parseData.js'
const isAudlt = PRODUCT_TYPE === 'adult';

export default {
  aiVideoList(data) {
    return axios.post('/api/v1/aivideo/getVideoList', data)
  },
  aiVideoLogList(data) {
    return axios.post('/api/v1/aivideo/getAiVideologList', data)
  },
  aiVideoLikeList(data) {
    return axios.post('/api/v1/aivideo/getAiVideolikeList', data)
  },
  aiVideoType(data) {
    return axios.post('/api/v1/aivideo/getVideoType', data)
  },
  aiVideoInfo(data) {
    if (isAudlt) {
      return axios.post('/api/v1/aivideo/getVideoinfo', data)
    } else {
      return axios.get(`/api/Speak/BookInfo/${data.video_id}`).then(parseVideoInfo)
    }
  },
  queryCaptions(url) {
    return axios.get(url);
  },
  collectVideo(id, type) {
    return axios.post('/api/v1/aivideo/collectVideo', { video_id: id, type })
  },
  // 收藏/取消收藏单词接口
  collectWord(params) {
    return axios.post('/api/v1/aivideo/collectWord', params)
  },
  // 掌握/取消掌握单词接口
  masterWord(params) {
    return axios.post('/api/v1/aivideo/masterWord', params)
  },
  // 掌握/取消掌握单词接口
  allMasterWord(params) {
    return axios.post('/api/v1/aivideo/allmasterWord', params)
  },
  collectSentence(data) {
    return axios.postjson('/api/v1/aivideo/collectSentence', data)
  },
  aiVideoWordsList(data) {
    return axios.post('/api/v1/aivideo/getDaoTuWordsList', data)
  },
  sentenceList(data) {
    return axios.post('/api/v1/aivideo/getSentenceList', data)
  },
  // 获取单词解释列表
  getExplainByWordId(data) {
    let method = 'post'
    if (data.word instanceof Array) {
      method = 'postjson'
    }
    return axios[method]('/api/v1/aivideo/getExplainByWordId', data)
  },
  // 通过单词获取单词详情
  getWordsInfo(data) {
    return axios.post('/api/v1/aivideo/getWordsInfo', data)
  },
  // 获取预览图
  getVideoImageById(videoId, second) {
    return axios.postjson('/api/v1/aivideo/getVideoImageById', { videoId, second })
  },
  // 获得单词的全部信息
  getAllByWord(word) {
    return axios.postjson('api/v1/aivideo/getAllByWord', { word })
  },
  // 获取单词的状态信息
  getWordState(word_list) {
    return axios.postjson('api/v1/aivideo/getWordState', { word_list })
  },
  //绑定例句和解释之间的关系
  contentSenAndWord(data) {
    return axios.postjson('api/v1/aivideo/contentSenAndWord', data)
  },

  //查询例句
  searchSentence(data) {
    return axios.post('/api/v1/aivideo/searchSentence', data)
  },
  //查询拓展局
  searchSenPhrase(data) {
    return axios.post('/api/v1/aivideo/searchSenPhrase', data)
  },
}