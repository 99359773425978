<template>
  <el-popover v-if="!isPunct && hoverSearch" placement="top" trigger="hover"
    :popper-style="`min-width: 80px;padding: 8px 12px; box-sizing: border-box;background:var(--primary); border: none;`"
    :show-after="500" @before-enter="beforEnter(props.word)" @after-leave="handleAfterLeave">
    <template #reference>
      <span :class="`popover-word-label ${isCollected ? currentCollectTextStyle : ''
        } ${isAllMaster ? 'onAllMasterWord' : ''}`" :style="`cursor: pointer;`" @click="clickWord">{{ props.word
        }}</span>
    </template>
    <div class="popover-word-content">
      <div style="text-align: left">{{ explain }}</div>
      <div class="footer-btn">
        <div class="collected-wrapper" @click="onCollectWord">
          <i v-if="!isCollected" class="iconfont icon-xihuan"></i>
          <i v-else class="iconfont icon-xihuan-" style="color: #ef4242"></i>
        </div>
        <div class="collected-wrapper" @click="onMasterWord">
          <i class="iconfont" :style="{ color: isMaster ? 'green' : '' }">&#xe615;</i>
        </div>
        <div class="collected-wrapper" @click="onAllMasterWord">
          <i v-if="!isAllMaster" class="iconfont">&#xe61f;</i>
          <i v-else class="iconfont">&#xe63b;</i>
        </div>
      </div>
    </div>
  </el-popover>
  <span v-else :class="`${isPunct ? 'punct' : 'popover-word-label'} ${isCollected ? currentCollectTextStyle : ''
    }`" style="cursor: pointer">{{ props.word }}</span>
</template>

<script setup>
import { ref, computed, defineProps, defineEmits } from "vue";
import { useStore } from "vuex";
import { isPunctuation } from "@/utils/tools";
import api from "@/api/video";
import EVENTS from "@/contants/events";
import emitter from "@/utils/eventBus";
import PLAY_STATE from "@/contants/playState";

const store = useStore();

const props = defineProps({
  word: String,
  isCollected: Boolean,
  isAllMaster: Boolean,
  isMaster: Boolean,
});

const emit = defineEmits([
  "changeVisible",
  "onAllMasterWord",
  "onMasterWord",
  "onCollectWord",
]);

const isPunct = computed(() => isPunctuation(props.word));
const isCollected = ref(props.isCollected);
const isAllMaster = ref(props.isAllMaster);
const isMaster = ref(props.isMaster);
const explain = ref("");
const autoPlay = ref(false);
const player = computed(() => store.state.videoPlayer.player);
const playState = computed(() => store.state.videoPlayer.playState);
const currentCollectTextStyle = computed(
  () => store.state.subtitles.collectTextStyleIndex
);

// 悬停查词
const hoverSearch = computed(
  () => store.state.videoSetting.settings.hoverSearch
);

// 获取单词释义
const beforEnter = async (word) => {
  const res = await api.getExplainByWordId({ word: word });
  const { code, data } = res;
  if (code !== 0 && data.length === 0) return;
  explain.value = data[word].explainlist.map((item) => item.content).join(";");
  isAllMaster.value = data[word]?.all_master;
  isCollected.value = data[word]?.is_like;
  isMaster.value = data[word]?.master_word;
  if (playState.value !== PLAY_STATE.paused) {
    autoPlay.value = true;
    emitter.emit('player-click-pause');
  }
};

// 收藏单词
const onCollectWord = async () => {
  const res = await api.collectWord({
    word: props.word,
    type: isCollected.value ? 2 : 1,
  });
  isCollected.value = !isCollected.value;
  if (isCollected.value) {
    emit("changeVisible", props.word);
  }
  emit("onCollectWord", props.word);
};

// 掌握单词
const onMasterWord = async () => {
  const res = await api.masterWord({
    word: props.word,
    type: isMaster.value ? 2 : 1,
  });
  isMaster.value = !isMaster.value;
  emit("onMasterWord", props.word, isMaster.value);
};

// 显示/隐藏单词
const onAllMasterWord = async () => {
  const res = await api.allMasterWord({
    word: props.word,
    type: isAllMaster.value ? 2 : 1,
  });
  isAllMaster.value = !isAllMaster.value;
  emit("onAllMasterWord", props.word);
};
// 打开右侧单词面板
const clickWord = (e) => {
  emitter.emit(EVENTS.WORD_PANEL, props.word);
};
// 处理鼠标移开
const handleAfterLeave = () => {
  if (autoPlay.value) {
    emitter.emit('player-click-play');
    autoPlay.value = false;
  }
}
</script>
<style lang="scss">
.popover-word-label {
  position: relative;
  border-radius: 4px;
  transition: all 0.2s ease 0s;
  white-space: pre;
  cursor: pointer;

  &:hover,
  &:focus {
    background: #666;
  }

  margin-right: 8px;

  &+.punct {
    margin-left: -8px;
    margin-right: 8px;
  }
}

[data-theme="light"] .popover-word-label {

  &:hover,
  &:focus {
    background: var(--primary20);
  }
}

.popover-word-content {
  font-size: 12px;
  color: var(--fff);
  text-align: center;
  white-space: pre-wrap;

  .collected-wrapper {
    width: 100%;
    line-height: 32px;
    text-align: center;
    border-radius: 8px;
    background-color: #ffffff24;
    margin-top: 4px;
  }

  .iconfont {
    font-size: 24px;
    cursor: pointer;
  }
}

.link-word-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 520px;
  height: 362px;
  border-radius: 16px;
  background-color: var(--1f222a);
  box-shadow: 0 8px 36px 0 #00000016;
  color: var(--fff);
  padding: 8px 24px 0;
  box-sizing: border-box;
  z-index: 9999;

  .title {
    text-align: center;
    font-size: 19px;
    line-height: 56px;
  }

  .content {
    height: 210px;
    overflow-y: scroll;

    .word-list {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;

      li {
        height: 22px;
        line-height: 22px;
        border-radius: 4px;
        padding: 0 4px;
        box-sizing: border-box;
        cursor: pointer;

        &:hover,
        &.selected {
          border: 1px solid var(--primary);
        }
      }
    }
  }

  .footer {
    display: flex;
    align-items: center;
    height: 80px;
    gap: 10px;

    button {
      flex: 1;
      line-height: 42px;
      color: var(--fff);
      font-size: 14px;
      text-align: center;
      border-radius: 30px;
      background-color: #35383f;
      border: none;
      cursor: pointer;

      &+button {
        background-color: var(--primary);
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.highLight {
  color: green;
}

.onAllMasterWord {
  opacity: 0;

  &:hover,
  &[aria-describedby] {
    opacity: 1;
  }
}

.footer-btn {
  display: flex;
  justify-content: space-around;
  gap: 5px;
}

.underline {
  border-bottom: 1px solid green;
}

.el-popper__arrow::before {
  border: none !important;
  background: var(--primary) !important;
}

:deep(.el-checkbox) {
  font-size: 14px;
  color: var(--fff);

  .el-checkbox__inner {
    background-color: transparent;
    border: 1px solid #35383f;
  }
}

:deep(.el-checkbox:hover) {
  .el-checkbox__inner {
    border-color: var(--primary);
  }
}

:deep(.el-checkbox.is-checked) {
  .el-checkbox__label {
    color: var(--fff);
  }
}

:deep(.el-checkbox__input.is-checked) {
  .el-checkbox__inner {
    background-color: var(--primary);

    &::after {
      border-color: var(--1f222a);
    }
  }
}
</style>